import Link from "next/link";
import * as React from "react";
import styles from "./index.module.css";

type TopListBoxProps = {
  title: string;
  link?: string;
  linkText?: string;
  contentsHolderLogoPath?: string;
  children: React.ReactNode;
};

export const TopListBox: React.FC<TopListBoxProps> = ({
  title,
  linkText,
  link,
  contentsHolderLogoPath,
  children,
}) => {
  return (
    <div className={styles.matchBox}>
      <h3 className={styles.matchBoxTitle}>
        {contentsHolderLogoPath && (
          <img
            className={styles.contentsHolderLogo}
            src={contentsHolderLogoPath}
            alt={"contents holder logo"}
          />
        )}
        {title}
      </h3>
      {children}
      {link && (
        <p className={styles.matchBoxLink}>
          <Link href={link}>{linkText}</Link>
        </p>
      )}
    </div>
  );
};
