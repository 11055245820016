import classNames from "classnames";
import { MatchListCard } from "@components/MatchList/MatchListCard";
import type { MatchListCard_MatchFragment } from "@components/MatchList/MatchListCard/__generated__/fragments";
import styles from "./index.module.css";
import type { FC } from "react";

type TopMatchListProps = {
  matches: Array<MatchListCard_MatchFragment>;
  showsTournamentName: boolean;
};

export const TopMatchList: FC<TopMatchListProps> = ({
  matches,
  showsTournamentName,
}) => {
  return (
    <div className={classNames(styles.wrapper)}>
      {matches?.map((match) => (
        <MatchListCard
          key={match.id}
          match={match}
          showsTournamentName={showsTournamentName}
        />
      ))}
    </div>
  );
};
