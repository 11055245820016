import classNames from "classnames";
import Link from "next/link";
import type { MatchListCard_MatchFragment } from "@components/MatchList/MatchListCard/__generated__/fragments";
import { paths } from "@libs/paths";
import { IFImg } from "@libs/utils/IFImg";
import { toJpDate, toJpTime } from "@libs/utils/date";
import { shouldShowMatchTime } from "@libs/utils/match";
import styles from "./index.module.css";
import type { FC } from "react";

type MatchListCardProps = {
  match: MatchListCard_MatchFragment;
  showsTournamentName: boolean;
};

export const MatchListCard: FC<MatchListCardProps> = ({
  match,
  showsTournamentName,
}) => {
  const time = shouldShowMatchTime(match)
    ? toJpTime(match.startsAt).toUpperCase()
    : "時間未定";

  return (
    <div className={styles.cardBox}>
      <Link
        href={paths.staticShops({
          genreSlug: `s-${match.masterTournament?.masterSportGenre.slug}`,
          tournamentSlug: match.masterTournament?.slug || "",
          matchSlug: `m-${match.id}`,
        })}
        className={styles.link}
      >
        <div className={classNames(styles.cardBoxContents)}>
          {showsTournamentName && (
            <div className={classNames(styles.tournamentName)}>
              <img
                className={styles.contentsHolderLogo}
                src={IFImg(match.contentsHolderLogoPath)}
                alt="contents holder logo"
              />
              {match.masterTournament && match.masterTournament.displayName}
            </div>
          )}
          <div className={styles.teamInfo}>
            <i
              className={classNames(styles.emblem)}
              style={{
                backgroundImage: `url(${IFImg(match.homeTeamIconPath)})`,
              }}
            />
            <div className={styles.teamCategory}>
              <p className={styles.teamName}>{match.homeTeamDisplayFullName}</p>
              <p className={styles.homeAndAway}>HOME</p>
            </div>
          </div>
          <div className={classNames(styles.teamInfo)}>
            <i
              className={classNames(styles.emblem)}
              style={{
                backgroundImage: `url(${IFImg(match.awayTeamIconPath)})`,
              }}
            />
            <div className={styles.teamCategory}>
              <p className={styles.teamName}>{match.awayTeamDisplayFullName}</p>
              <p className={styles.homeAndAway}>AWAY</p>
            </div>
          </div>
          <div className={styles.additionalInfo}>
            <div className={styles.tournamentDate}>
              {toJpDate(match.startsAt).toUpperCase()} {time && time + "〜"}
            </div>
            {match.label && <div className={styles.label}>{match.label}</div>}
          </div>
        </div>
      </Link>
    </div>
  );
};
