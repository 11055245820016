import classNames from "classnames";
import * as React from "react";
import {
  ReservationCancelTypeEnumV2,
  ReservationStateTypeEnum,
} from "@graphql/__generated__/types";
import styles from "./index.module.css";

type Props = {
  state: ReservationStateTypeEnum;
  cancelType?: ReservationCancelTypeEnumV2;
};
const ReservationStatus: React.FC<Props> = ({ state, cancelType }) => {
  const getStateStr = () => {
    switch (state) {
      case ReservationStateTypeEnum.Pending:
        return "予約リクエスト中";
      case ReservationStateTypeEnum.Confirmed:
        return "予約確定";
      case ReservationStateTypeEnum.Rejected:
        return "予約できませんでした";
      case ReservationStateTypeEnum.Canceled:
        switch (cancelType) {
          case ReservationCancelTypeEnumV2.ByShopDueToShop:
            return "店舗によるキャンセル";
          case ReservationCancelTypeEnumV2.ByShopDueToNoShow:
            return "無断キャンセル";
          case ReservationCancelTypeEnumV2.ByShopDueToMatch:
            return "キャンセル(試合中止・日程変更)";
          case ReservationCancelTypeEnumV2.PaymentError:
            return "予約できませんでした";
          default:
            return "キャンセル";
        }
      case ReservationStateTypeEnum.Visited:
        return "来店済";
    }
  };

  return (
    <span className={classNames(styles.state, styles[state.toLowerCase()])}>
      {getStateStr()}
    </span>
  );
};

export default ReservationStatus;
