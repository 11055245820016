import * as React from "react";
import type { MatchListCard_MatchFragment } from "@components/MatchList/MatchListCard/__generated__/fragments";
import { TopListBox } from "@components/TopListBox";
import { TopMatchList } from "@components/TopMatchList";
import type {
  UsersSportGenreType,
  UsersTournamentType,
} from "@graphql/__generated__/types";
import { paths } from "@libs/paths";

type TournamentMatchBoxProps = {
  matches: Array<MatchListCard_MatchFragment>;
  tournament: Pick<UsersTournamentType, "id" | "name" | "slug"> & {
    masterSportGenre: Pick<UsersSportGenreType, "slug">;
  };
};

export const TournamentMatchBox: React.FC<TournamentMatchBoxProps> = (
  props
) => {
  const { matches, tournament } = props;

  if (matches.length === 0) {
    return null;
  }

  return (
    <TopListBox
      link={paths.staticMatches({
        genreSlug: `s-${tournament.masterSportGenre.slug}`,
        tournamentSlug: tournament.slug || "",
      })}
      title={tournament.name}
      linkText={`${tournament.name}の日程を見る`}
      contentsHolderLogoPath={matches[0].contentsHolderLogoPath ?? undefined}
    >
      {/* NOTE: Topページの試合からスポーツバーを探すセクションはステークホルダーの部分は非表示 */}
      <TopMatchList matches={matches} showsTournamentName={false} />
    </TopListBox>
  );
};
